import dashboard from '@/images/icons/dashboard.svg';
import pen from '@/images/icons/pen.svg';
import colorpen from '@/images/icons/dashboard/colorpen.svg';
import version from '@/images/icons/version.svg';
import inkpen from '@/images/icons/inkpen.svg';
import articles from '@/images/icons/articles.svg';
import engine from '@/images/icons/engine.svg';
import help from '@/images/icons/help.svg';
import logout from '@/images/icons/logout.svg';
import logoutorange from '@/images/icons/logoutorange.svg';
import article from '@/images/icons/article.svg';
import goback from '@/images/icons/goback.svg';
import gobackdark from '@/images/icons/gobackdark.svg';
import bluerobot from '@/images/icons/bluerobot.svg';
import spinengine from '@/images/icons/spinengine.svg';
import camera from '@/images/icons/camera.svg';
import defaulticon from '@/icons/icon.svg';
import logo from '@/images/reverbslogoside.svg';
import logoblack from '@/images/reverbslogopreto.svg';
import revhappy from '@/lib/images/rev/rev-happy.svg';
import sleepyrev from '@/lib/images/rev/sleepy-rev.svg';
import revwaving from '@/lib/images/rev/rev-waving.svg';
import revwavingsm from '@/lib/images/rev/rev-waving-sm.svg';
import revbigwaving from '@/lib/images/rev/rev-big-waving.svg';
import revcrownwaving from '@/lib/images/rev/rev-crown-waving.svg';
import revcrownwavingstraight from '@/lib/images/rev/rev-crown-waving-straight.svg';
import iconletter from '@/lib/images/icons/reverbsletter.svg';
import perfilconfig from '@/icons/dashboard/perfilconfig.svg';
import projconfig from '@/icons/dashboard/projconfig.svg';
import payconfig from '@/icons/dashboard/payconfig.svg';
import globe from '@/icons/dashboard/globe.svg';
import growth from '@/icons/dashboard/growth.svg';
import integration from '@/icons/dashboard/integration.svg';
import newco from '@/icons/dashboard/newco.svg';
import hotmart from '@/icons/dashboard/hotmart.svg';
import platform from '@/icons/dashboard/platform.svg';
import shopify from '@/icons/dashboard/shopify.svg';
import wbuy from '@/icons/dashboard/wbuy.svg';
import wiz from '@/icons/dashboard/wiz.svg';
import wp from '@/icons/dashboard/wp.svg';
import helper from '@/icons/dashboard/helper.svg';
import checkmark from '@/icons/dashboard/checkmark.svg';
import pdf from '@/icons/dashboard/pdf.svg';
import docs from '@/icons/dashboard/docs.svg';
import sheets from '@/icons/dashboard/sheets.svg';
import revsad from '@/lib/images/rev/rev-sad.svg';
import revcolorwave from '@/lib/images/rev/rev-waving-color.svg';
import dots from '@/icons/dashboard/dots.svg';
import filter from '@/icons/dashboard/filter.svg';
import wrench from '@/icons/dashboard/wrench.svg';
import external from '@/icons/dashboard/export.svg';
import infobook from '@/icons/dashboard/infobook.svg';
import exclamation from '@/icons/dashboard/exclamation.svg';
import clienthelp from '@/icons/dashboard/clienthelp.svg';
import clienthelp2 from '@/icons/dashboard/clienthelp2.svg';

const iconMap = {
    exclamation,
    clienthelp,
    clienthelp2,
    filter,
    infobook,
    external,
    wrench,
    checkmark,
    helper,
    hotmart,
    platform,
    shopify,
    wbuy,
    wiz,
    wp,
    globe,
    growth,
    integration,
    newco,
    dashboard,
    pen,
    logoblack,
    colorpen,
    dots,
    version,
    inkpen,
    iconletter,
    articles,
    defaulticon,
    engine,
    logo,
    help,
    logout,
    logoutorange,
    article,
    goback,
    gobackdark,
    bluerobot,
    spinengine,
    camera,
    perfilconfig,
    projconfig,
    payconfig,
    sheets,
    pdf,
    docs,
};

export type IconName = keyof typeof iconMap;

interface IconProps {
    want: IconName;
    className?: string;
}

export default function Icon({ want, className }: IconProps) {
    const IconSvg = iconMap[want] || iconMap.defaulticon;
    return <IconSvg unoptimized="true" className={className} aria-hidden="true" />;
}

const revMap = {
    happy: revhappy,
    waving: revwaving,
    wavingsm: revwavingsm,
    defaultrev: revwaving,
    bigwaving: revbigwaving,
    crownwaving: revcrownwaving,
    crownwavingstraight: revcrownwavingstraight,
    sad: revsad,
    sleepy: sleepyrev,
    colorwaving: revcolorwave,
};

interface RevProps {
    is: keyof typeof revMap;
    className?: string;
}

export function Rev({ is, className }: RevProps) {
    const RevSvg = revMap[is] || revMap.defaultrev;
    return <RevSvg unoptimized="true" className={className} aria-hidden="true" />;
}
