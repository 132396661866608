'use client';

import Link from 'next/link';
import { useTranslations } from 'next-intl';

/* eslint-disable jsx-a11y/label-has-associated-control */
export default function FormExtra() {
    const t = useTranslations('LoginPage');

    return (
        <div className="flex w-full flex-row justify-between sm:px-2">
            <div className="flex flex-row items-center">
                <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    aria-checked
                    className="rounded-[4px] border border-secondary-btn bg-foreground text-secondary-btn transition-all
                    aria-checked:ring-primary/75 aria-checked:ring-offset-[0.5px] aria-checked:ring-offset-primary/75
                    [&:not(:checked)]:ring-secondary-btn/75 [&:not(:checked)]:ring-offset-[0.5px] [&:not(:checked)]:ring-offset-secondary-btn/75"
                />
                <label
                    htmlFor="remember-me"
                    className="ml-2 select-none font-sans text-lg font-normal text-foreground opacity-80"
                >
                    {t('rememberMe')}
                </label>
            </div>

            <div className="flex flex-row items-center">
                <Link
                    href="/login/forgotpassword"
                    className="self-center font-sans text-lg font-normal hover:underline"
                >
                    {t('forgotPassword')}
                </Link>
            </div>
        </div>
    );
}
