'use client';

import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';
import Link from 'next/link';
import { ReverbsLogoLogin } from '@/components/ReverbsLogo';
import bg_waves from '@/images/Zwavesbg.png';
import bg_stars from '@/images/Zestrelasbg.png';
import bg_rev from '@/images/Zrevbg.png';
import Icon from '@/components/Icon';
import SignInForm from '@/components/SignInForm';

export default function LoginPage() {
    const [isLoading, setIsLoading] = useState(true);
    const t = useTranslations('LoginPage');

    useEffect(() => {
        setIsLoading(false);
    }, []);

    if (isLoading) {
        return (
            <div className="mt-[40vh] flex w-full animate-pulse items-center justify-center p-2 text-3xl">
                {t('loading')}
            </div>
        );
    }

    return (
        <div
            className="overflow-hidden bg-cover bg-bottom-right"
            style={{
                backgroundImage: `url(${bg_waves.src})`,
            }}
        >
            <div
                className="fixed z-10 grid size-full select-text auto-cols-auto grid-flow-row auto-rows-auto content-center
                    justify-center justify-items-center gap-x-[1vw] overflow-auto py-[6vh] selection:bg-primary/75 sm:size-auto sm:justify-items-start sm:gap-y-[3svh]
                    sm:px-[2.5vw] sm:pl-[7vw] xl:w-[57.5%]"
            >
                <div className="select-none px-[4vw] sm:mb-0 sm:px-0 sm:pt-[10vh]">
                    <ReverbsLogoLogin />
                </div>
                <div
                    className="my-[5svh] flex h-fit w-full select-none flex-col px-[5vw] py-6 font-sans
                        text-xl backdrop-blur-md  backdrop-brightness-200
                        sm:my-0 sm:w-[85%] sm:gap-[3vh] sm:p-0 sm:ring-0 sm:ring-offset-0
                        sm:backdrop-blur-none sm:backdrop-brightness-100 lg:text-4xl lg:leading-9"
                >
                    <div className="px-2.5 font-bold sm:px-0">{t('title')}</div>
                    <div className="px-2.5 font-normal sm:px-0">{t('description')}</div>
                </div>

                <SignInForm />

                <div className="flex w-[90%] flex-col items-center text-lg sm:w-[57.5%] md:items-stretch">
                    <div className="mb-[0.5svh] flex h-[1/2] flex-col items-center md:mb-[1.5svh] md:flex-row md:gap-2">
                        <div>{t('question')}</div>
                        <Link href="/register" className="font-bold text-tertiary hover:underline ">
                            {t('cta')}
                        </Link>
                    </div>
                    <Link
                        href="/"
                        className="mt-2 flex h-8 w-28 justify-center gap-x-2 rounded-xl opacity-75 ring-2 ring-foreground"
                    >
                        <Icon want="goback" className="self-center" />
                        <div className="self-center pt-1 leading-none">{t('back')}</div>
                    </Link>
                </div>
            </div>
            <div
                className="absolute inset-x-0 h-screen animate-pulse bg-contain bg-bottom-right bg-no-repeat blur-sm backdrop-brightness-50 sm:backdrop-brightness-100"
                style={{
                    backgroundImage: `url(${bg_stars.src})`,
                }}
            />
            <div
                className="h-screen animate-floatingbg bg-contain
                        bg-bottom bg-no-repeat opacity-75 drop-shadow-[7px_18px_15px_rgba(255,255,255,0.15)] xl:opacity-100"
                style={{
                    backgroundImage: `url(${bg_rev.src})`,
                }}
            />
        </div>
    );
}
