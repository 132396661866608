'use client';

/* eslint-disable no-console */

import { useTranslations } from 'next-intl';
import type { FormEvent } from 'react';
import { Input } from './ui/Input';
import { Button } from '@/components/ui/Button';
import FormExtra from '@/components/FormExtra';
import { useRouter } from 'next/navigation';
import React, { useState } from 'react';
import { getProjects, signInWithForm } from '@/lib/auth';
import { auth } from '@/lib/firebase';
import BarLoader from 'react-spinners/BarLoader';
import { toast } from 'sonner';
import { FirebaseError } from 'firebase/app';

/**
 * Interface representing the structure of the response from the getProjects function.
 */
interface GetProjectsResponse {
    projects: Array<{
        projectID: string;
        projectName: string;
    }>;
}

/**
 * Type definition for login errors.
 */
type LoginError = {
    code: string;
    message: string;
};

/**
 * Maps Firebase error codes to user-friendly error messages.
 * @param {FirebaseError} error - The Firebase error object.
 * @returns {string} A user-friendly error message.
 */
const getErrorMessage = (error: FirebaseError, e: any): string => {
    switch (error.code) {
        case 'auth/invalid-credential':
        case 'auth/user-not-found':
        case 'auth/wrong-password':
            return e('case1');
        case 'auth/too-many-requests':
            return e('case2');
        case 'auth/user-disabled':
            return e('case3');
        default:
            return e('default');
    }
};

/**
 * SignInForm component for handling user authentication.
 * @returns {JSX.Element} The rendered sign-in form.
 */
export default function SignInForm() {
    const t = useTranslations('LoginPage');
    const e = useTranslations('FirebaseErrors');
    const m = useTranslations('SignInErrors');
    // State for managing login errors
    const [error, setError] = useState<LoginError | null>(null);
    // State for tracking form submission status
    const [isSubmitting, setIsSubmitting] = useState(false);
    // Next.js router for navigation
    const router = useRouter();

    /**
     * Handles the form submission for user sign-in.
     * @param {FormEvent<HTMLFormElement>} event - The form submission event.
     */
    const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        // Show loading toast
        const toastLogin = toast.loading(m('toastLoginLoading'));
        setIsSubmitting(true);
        setError(null); // Reset error state before attempting login

        // Extract email and password from form data
        const formData = new FormData(event.currentTarget);
        const email = formData.get('email') as string;
        const password = formData.get('password') as string;

        try {
            // Attempt to sign in
            const response = await signInWithForm(email, password);
            if (response.success) {
                const userId = auth.currentUser?.uid;
                const userIdToken = await auth.currentUser?.getIdToken();

                try {
                    // Fetch user's projects
                    const projectsResponse = (await getProjects(userId, userIdToken)) as GetProjectsResponse;

                    if (projectsResponse.projects && projectsResponse.projects.length > 0) {
                        // If user has projects, redirect to the first project
                        router.refresh();
                        await router.push(`/dashboard/${projectsResponse.projects[0].projectID}`);
                        toast.success(m('toastLoginSuccessProject'), {
                            id: toastLogin,
                        });
                    } else {
                        // If user has no projects, redirect to dashboard
                        router.refresh();
                        await router.push('/dashboard');
                        toast.success(
                            m('toastLoginSuccessNew', {
                                name: response.displayName,
                            }),
                            {
                                id: toastLogin,
                            },
                        );
                    }
                } catch (projectError) {
                    // Handle errors in fetching projects
                    console.error('Error fetching projects:', projectError);
                    router.refresh();
                    await router.push('/dashboard');
                    toast.success(m('toastLoginSuccessFirst'), {
                        id: toastLogin,
                    });
                }
            } else {
                const { err } = response;
                if (err instanceof FirebaseError) {
                    // Handle Firebase-specific errors
                    const errorMessage = getErrorMessage(err, e);
                    setError({ code: err.code, message: errorMessage });
                    toast.error(errorMessage, { id: toastLogin });
                } else {
                    // Handle unknown errors
                    setError({
                        code: 'unknown',
                        message: m('toastLoginErrorUnknown'),
                    });
                    toast.error(m('toastLoginErrorUnknown'), {
                        id: toastLogin,
                    });
                }
            }
        } catch (err) {
            // Handle generic errors
            setError({
                code: 'unknown',
                message: m('toastLoginErrorUnknownLogin'),
            });
            toast.error(m('toastLoginErrorUnknownLogin'), {
                id: toastLogin,
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="contents">
            <div className="flex h-fit w-[90%] flex-col gap-[1.5svh] sm:mb-[-2vh] sm:w-[57.5%] sm:gap-3">
                {/* Email input field */}
                <Input
                    id="email"
                    type="email"
                    name="email"
                    placeholder={t('email')}
                    required
                    className="border border-background/75 opacity-90 shadow-md shadow-foreground/25
                        transition-all duration-500
                        focus:opacity-100 focus:shadow-reverbs focus:ring-0"
                />
                {/* Password input field */}
                <Input
                    id="password"
                    type="password"
                    name="password"
                    placeholder={t('password')}
                    required
                    className="border border-background/75 opacity-90 shadow-md shadow-foreground/25
                        transition-all duration-500
                        focus:opacity-100 focus:shadow-reverbs focus:ring-0"
                />
                {/* Additional form elements */}
                <FormExtra />
                {/* Error message display */}
                {error && (
                    <div className="w-fit rounded-3xl bg-salmon px-2 py-1 text-background">
                        <p>{error.message}</p>
                    </div>
                )}
            </div>
            <div className="my-4 w-[90%] sm:w-[57.5%] md:my-0">
                {/* Submit button */}
                <Button
                    type="submit"
                    disabled={isSubmitting}
                    className="
h-fit w-full rounded-xl transition-all
active:text-background active:duration-200 active:ease-in sm:h-full"
                >
                    {isSubmitting ? (
                        // Show loading indicator when submitting
                        <BarLoader
                            color="#a175ff"
                            loading={isSubmitting}
                            width="100%"
                            height="8px"
                            speedMultiplier={0.5}
                            className="my-3 rounded-full"
                        />
                    ) : (
                        <span>{t('mailLogin')}</span>
                    )}
                </Button>
            </div>
        </form>
    );
}
