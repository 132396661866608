import Image from 'next/image';
import logologin from '@/images/reverbslogologin.png';
import logoregister from '@/images/logoregister.png';

export function ReverbsLogoLogin() {
    return (
        <Image
            src={logologin}
            alt="Reverbs Logo"
            priority
            className="transition-all duration-1000 hover:brightness-105 hover:-hue-rotate-15"
        />
    );
}

export function ReverbsLogoRegister() {
    return (
        <div className="w-[75vw] place-content-end self-center pb-[4svh] md:w-[30vw] lg:w-[22.44vw] lg:pb-0">
            <Image
                src={logoregister}
                alt="Reverbs Logo"
                priority
                className="transition-all duration-1000 hover:brightness-105 hover:-hue-rotate-15"
            />
        </div>
    );
}

export function ReverbsLogoCrude() {
    return (
        <Image
            src={logoregister}
            alt="Reverbs Logo"
            className="transition-all duration-1000 hover:brightness-105 hover:-hue-rotate-15"
        />
    );
}
